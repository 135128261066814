.hero {

	&.has-bg-color {
		position: relative;
		background-color: transparent;
		z-index: auto;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 85px;
			max-width: 1280px;
			margin: 0 auto;
			border-radius: 20px;
			width: 100%;
			height: 640px;
			background: linear-gradient(45deg, rgba(169,250,251,1) 0%, rgba(56,221,223,1) 31%, rgba(78,63,171,1) 74%);
			// background: linear-gradient(45deg, rgba(169,250,251,1) 0%, rgba(78,63,171,1) 62.5%);
			z-index: 0;
		}
	}

	&[class*=illustration-]::after {
		height: 640px;
	}
}
