.tagtitle {
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 20rem;
    color: #A9FAFB;
    background-color: #5338B1;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    margin: 1rem auto 4rem auto;

    &.is-light {
        background-color: #A9FAFB;
        color: #5338B1;    
    }
}