.cta {
	&.full-width {
		background-color: get-color(dark, 1);

		.cta-inner {
			background-color: transparent;
		}
	}

	&.has-bg-color {
		position: relative;
		background-color: transparent;
		z-index: auto;

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 50%;
			background: color-bg(bg-color);
		}
	}
}

.cta-inner {
	padding-left: 16px;
	padding-right: 16px;
	background-color: get-color(secondary, 1);
	// background-image: url(../../../images/cta-illustration.svg);
	// background-repeat: no-repeat;
	// background-size: cover;
	border-radius: 20px;
    color: white;
    background-color: #5338B1;
}

@include media( '<=medium' ) {
	.cta {
		margin-top: 2rem;
	}
}

@include media( '>medium' ) {
	.cta-inner {
		padding-left: 48px;
		padding-right: 48px;
	}
}
